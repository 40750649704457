import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { GoogleMap as GoogleMapComponent, useJsApiLoader } from "@react-google-maps/api";

import { removeExt, PdImage } from "@/utils/image";

//import PurpleDoorLogoRect from "../Logo/PurpleDoorLogoRect";
//import Verified from "../Logo/Verified";

import {
    HeartIcon,
    ShareIcon,
    XCircleIcon,
    QuestionMarkCircleIcon,
} from "@heroicons/react/outline";
import {
    HeartIcon as SolidHeartIcon,
    XCircleIcon as SolidXCircleIcon,
    //ExclamationCircleIcon,
} from "@heroicons/react/solid";

import ShareButton from "../common/ShareButton";
import { communityTypes } from "@/utils/lists";

import Tooltip from "../ui/Tooltip";
import { toggleFavourite, toggleExOut } from "../../actions/localActions";
import { renameStatus } from "@/utils/rename";
import memory from "@/utils/memory";
import { getCommunityTypes, CommunityTypesDialog } from "../community/CommunityUtils";
import Dialog from "@/ui/Dialog";

const getCommunityTypesHelp = (facilityType) => {
    let output = [];

    if (facilityType) {
        const types = facilityType.split(";");

        console.log("types", types);

        types.forEach((type) => {
            const c = communityTypes.find((c) => c.value === type);
            if (c) {
                output.push(c);
            }
        });

        console.log("output", output);
    }

    return output;
};

export const checkSpecialOffer = (community) => {
    let specialOffer = false;

    if (community.unit && community.unit.length > 0) {
        community.unit.forEach((unit) => {
            if (unit.specialOffer) {
                specialOffer = true;
            }
        });
    }

    return specialOffer;
};

export const CommunityCardSkeleton = ({ imageHeight = 256, searchInMap = false }) => {
    return (
        <div className="relative cursor-pointer">
            <div className="rounded overflow-hidden shadow-md">
                <div
                    className="w-full bg-gray-200 animate-pulse"
                    style={{ height: imageHeight }}
                />

                <div className="py-3 px-4 h-32 flex flex-col justify-between">
                    <div className="flex text-sm">
                        {[1, 2, 3].map((t, i) => (
                            <div key={i} className="flex">
                                <div className="w-16 bg-gray-200 animate-pulse"></div>
                                {i !== 3 - 1 ? (
                                    <div className="px-2 text-gray-200">|</div>
                                ) : null}
                            </div>
                        ))}
                    </div>

                    <div className="mt-2 w-72 h-6 bg-gray-200 animate-pulse"></div>

                    <div className="mt-1 flex justify-between items-center">
                        <div className="flex text-gray-600 font-semibold">
                            <div className="w-32 bg-gray-200 animate-pulse"></div>
                            <div className="pl-2 text-gray-200">&bull;</div>
                            <div className="ml-2 pl-2 w-32 bg-gray-200 animate-pulse"></div>
                        </div>

                        <div className="w-32 bg-gray-200 animate-pulse"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

function getUrlFromMemory(nameId) {
    const url = memory.getItem(nameId);
    if (url) {
        console.log("url from memory", url);
    }
    return url ? url : "";
}

function isNew(unit) {
    if (unit.startDate) {
        const today = moment().format("YYYY-MM-DD");
        if (new Date(today) - new Date(unit.startDate) < 14 * 86400 * 1000) {
            return true;
        }
    }

    return false;
}

export function getAvailability(community) {
    let availability = "Not Available";

    // check the community unit availability
    if (community.unit) {
        const today = moment().format("YYYY-MM-DD");

        // priority: Available Newly => Available Now => Coming Soon => Not Available

        community.unit.forEach((unit) => {
            if (unit.availability) {
                if (availability === "Not Available") {
                    if (unit.availability === "Available Now") {
                        availability = "Available Now";
                    } else if (unit.availability === "Coming Soon") {
                        if (today >= unit.startDate) {
                            availability = "Available Now";
                        } else {
                            availability = "Coming Soon";
                        }
                    }
                } else if (availability === "Available Now") {
                    if (unit.availability === "Available Now") {
                        availability = "Available Now";
                    }
                } else if (availability === "Coming Soon") {
                    if (unit.availability === "Available Now") {
                        availability = "Available Now";
                    }
                }
            }
        });
    }

    return availability;
}

// Define a list of libraries to load from the Google Maps API
const libraries = ["places", "drawing", "geometry"];

const CommunityCard = ({
    community,
    imageHeight = 236,
    searchInMap = false,
    enableLink = true,
}) => {
    const dispatch = useDispatch();
    const { favourites, exOut } = useSelector((state) => state.local);
    const [googleFirstPhotoUrl, setGoogleFirstPhotoUrl] = useState("");
    const [openHelp, setOpenHelp] = useState(false);
    const address = community.contact[0].address[0];
    const [googleMap, setGoogleMap] = useState(null);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY,
        libraries,
    });

    const onLoad = React.useCallback(function callback(map) {
        setGoogleMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setGoogleMap(null);
    }, []);

    //console.log("CommunityCard", community);

    useEffect(() => {
        const getGoogleData = () => {
            const url = memory.getItem(community.nameId);
            if (url) {
                console.log("community_image Load");
                //community.googleFirstPhotoUrl = url;
                setGoogleFirstPhotoUrl(url);
                return;
            } else {
                console.log("community_image not found");
            }

            const google = window.google;

            const service = new google.maps.places.PlacesService(
                document.getElementById("id-community-" + community.nameId)
            );

            service.getDetails(
                {
                    placeId: community.communityPlaceId,
                    fields: ["name", "type", "photo", "url"],
                },
                function (place, status) {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        console.log("google map result:", place);

                        if (place.photos && place.photos.length > 0) {
                            const url = place.photos[0].getUrl({ maxWidth: 512 });
                            setGoogleFirstPhotoUrl(url);
                            memory.setItem(community.nameId, url);
                            console.log("community_image Save");
                        }
                    } else {
                        console.log("community_image google failed", status);
                    }
                }
            );
        };

        // this is needed for testing:
        // wait 1 sec to make sure winodw.google is loaded
        if (
            googleMap !== null &&
            community.onboarded !== true &&
            community.aiImported !== true &&
            community.communityPlaceId &&
            googleFirstPhotoUrl === ""
        ) {
            getGoogleData();
        }
    }, [
        community.onboarded,
        community.aiImported,
        community.communityPlaceId,
        googleFirstPhotoUrl,
        googleMap,
    ]);

    const handleFavourite = (e, favoriteState, _favourites) => {
        e.stopPropagation();
        dispatch(toggleFavourite(community.nameId));

        gtag("event", "favorite", {
            event_category: "engagement",
            event_label: "favorite_button",
            value: favoriteState ? 0 : 1,
            item_id: community.nameId,
        });

        gtag("event", "engagementCountUpdated", {
            favorites_count: _favourites ? Object.keys(_favourites).length : 1,
        });

        const engagementCount = trackButtonClick("engagement");
        gtag("event", "engagementCountUpdated", {
            engagement_count: engagementCount,
        });
    };

    const handleExOut = (e) => {
        e.stopPropagation();
        dispatch(toggleExOut(community.nameId));
    };

    let imageUrl = "";
    let availability = "Not Available";
    if (community.onboarded || community.aiImported) {
        let mainImage = community.medias.find((m) => m.type === "Main");
        if (mainImage) {
            imageUrl = mainImage.url;
        }

        if (!imageUrl) {
            mainImage = community.medias.find((m) => m.type === "Community");
            if (mainImage) {
                imageUrl = mainImage.url;
            }
        }

        if (!imageUrl) {
            mainImage = community.medias.find((m) => m.type === "Activity");
            if (mainImage) {
                imageUrl = mainImage.url;
            }
        }

        if (!imageUrl) {
            mainImage = community.medias.find((m) => m.type === "Amenity");
            if (mainImage) {
                imageUrl = mainImage.url;
            }
        }

        if (!imageUrl) {
            mainImage = community.medias.find((m) => m.type === "Dining");
            if (mainImage) {
                imageUrl = mainImage.url;
            }
        }

        if (!imageUrl) {
            mainImage = community.medias.find((m) => m.type === "Unit");
            if (mainImage) {
                imageUrl = mainImage.url;
            }
        }

        availability = getAvailability(community);
        if (availability === "Available Now") {
            availability = "Available";
        }
    } else if (googleFirstPhotoUrl) {
        imageUrl = googleFirstPhotoUrl;
    } else if (community.googleFirstPhotoUrl) {
        imageUrl = community.googleFirstPhotoUrl;
    }

    // console.log({
    //     googleFirstPhotoUrl,
    //     community: community.googleFirstPhotoUrl,
    //     imageUrl,
    // });

    const paidPlan = community.plan && community.plan.startsWith("Paid");

    return (
        <LinkOrDiv community={community} enableLink={enableLink}>
            {paidPlan && availability !== "Not Available" ? (
                <div
                    className={`absolute top-2 left-2 px-3 py-1 text-sm rounded uppercase font-bold
                         bg-opacity-90 backdrop-blur 
                        ${
                            availability === "Available"
                                ? "bg-green-600 text-white"
                                : "bg-primary-600 text-white"
                        }`}
                >
                    {availability === "Available" ? "Vacancy" : availability}
                </div>
            ) : null}

            {paidPlan &&
            community.waitlist &&
            availability !== "Available" &&
            availability !== "Coming Soon" ? (
                <div
                    className={`absolute top-2 left-2 px-3 py-1 text-xs rounded uppercase font-bold
                     bg-opacity-90 backdrop-blur bg-yellow-600 text-white`}
                >
                    Waitlist
                </div>
            ) : null}

            {paidPlan && community.specialIncentive ? (
                <div
                    className={`absolute bottom-[200px] left-2 px-3 py-1 text-xs rounded uppercase font-bold
                         bg-opacity-90 backdrop-blur bg-primary-600 text-white`}
                >
                    Special Incentive
                </div>
            ) : null}

            {paidPlan && community.upcomingEvent ? (
                <div
                    className={`absolute bottom-[200px] right-2 px-3 py-1 text-xs rounded uppercase font-bold
                         bg-opacity-90 backdrop-blur bg-primary-600 text-white`}
                >
                    Upcoming Event
                </div>
            ) : null}

            {paidPlan ? (
                <div
                    className={`absolute top-2 right-2 px-3 py-1 text-xs rounded uppercase font-bold
                         bg-opacity-90 backdrop-blur bg-primary-600 text-white`}
                >
                    Premium
                </div>
            ) : null}

            {/*<div className="absolute top-2 right-2">
                <div className="h-8 grid grid-cols-2 gap-4 px-2 rounded bg-gray-200 bg-opacity-40 backdrop-blur">
                    <Tooltip title="Favorite">
                        <button onClick={handleFavourite}>
                            {favourites && favourites[community.nameId] ? (
                                <SolidHeartIcon className="w-8 h-8 p-1 text-primary-400 hover:text-white hover:bg-primary-400 hover:rounded-full" />
                            ) : (
                                <HeartIcon className="w-8 h-8 p-1 text-gray-900 hover:text-white hover:bg-primary-400 hover:rounded-full" />
                            )}
                        </button>
                    </Tooltip>

                    <Tooltip title="Share">
                        <ShareButton community={community} />
                    </Tooltip>
                </div>
            </div>*/}

            {/*community.onboarded && paidPlan ? (
                <div
                    className="absolute left-2"
                    style={{
                        top: community.specialOffer ? imageHeight - 65 : imageHeight - 31,
                    }}
                >
                    <div className="px-2 py-0.5 flex items-center bg-white rounded">
                        <Verified width={18} height={22} />
                        <span className="ml-1 font-bold text-primary-500">VERIFIED</span>
                    </div>
                </div>
                ) : null*/}

            {/*community.specialOffer && paidPlan ? (
                <div className="absolute left-0 right-0" style={{ top: imageHeight - 31 }}>
                    <div className="px-2 py-1 flex items-center bg-primary-500 text-primary-50 font-headline font-bold uppercase">
                        <ExclamationCircleIcon className="w-6 h-6 mr-2" />
                        {community.specialOffer + "*"}
                    </div>
                </div>
            ) : null*/}

            <div className="rounded overflow-hidden shadow-sm border border-gray-300">
                {imageUrl !== "" ? (
                    <div>
                        <PdImage
                            src={removeExt(imageUrl) + ".md.jpg"}
                            originalSrc={imageUrl}
                            alt="Community Photo"
                            className="w-full object-cover object-center"
                            style={{ height: imageHeight }}
                        />
                        {/*<img
                            src={imageUrl}
                            alt="Community Photo"
                            className="w-full object-cover object-center"
                            style={{ height: imageHeight }}
                        />*/}
                    </div>
                ) : (
                    <img
                        src="images/noImageAvailable.png"
                        alt="Community Photo"
                        className="w-full object-cover object-center"
                        style={{ height: imageHeight }}
                    />
                )}

                <div
                    className={`py-3 ${
                        searchInMap ? "px-3" : "px-4"
                    } h-[192px] flex flex-col`}
                >
                    <div className="h-20 flex justify-between items-start">
                        <div className="text-primary-400 text-xs font-bold uppercase flex flex-wrap items-start">
                            {getCommunityTypes(community.facilityType)}

                            <button
                                className="ml-4 text-primary-500 hover:text-white hover:bg-primary-500 rounded-full"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpenHelp((x) => !x);
                                }}
                            >
                                <QuestionMarkCircleIcon className="w-5 h-5 " />
                            </button>
                        </div>
                    </div>

                    <div className="h-5 flex space-x-2">
                        {community.onboarded ? (
                            <div className="px-2 py-0.5 text-xs uppercase font-black text-white rounded-full bg-primary-400">
                                Claimed
                            </div>
                        ) : null}

                        {checkSpecialOffer(community) && paidPlan ? (
                            <div className="px-2 py-0.5 text-xs uppercase font-black text-white rounded-full bg-primary-400">
                                Special Incentive
                            </div>
                        ) : null}
                    </div>

                    <div className="h-12 text-gray-900 text-lg font-black text-left">
                        {community.name}
                    </div>

                    <div className="mt-1 flex justify-between items-center">
                        <div className="text-gray-500 text-sm font-bold">
                            {address?.city}, {address?.state}
                            <span className="pl-2">&bull;</span>
                            {community.numberOfUnits ? (
                                <span className="pl-2">
                                    {community.numberOfUnits} Units
                                </span>
                            ) : null}
                        </div>

                        <div className="flex">
                            <Tooltip
                                title={
                                    favourites && favourites[community.nameId]
                                        ? "Remove from Favorites"
                                        : "Add to Favorites"
                                }
                            >
                                <button
                                    onClick={(e) =>
                                        handleFavourite(
                                            e,
                                            favourites && favourites[community.nameId],
                                            favourites
                                        )
                                    }
                                >
                                    {favourites && favourites[community.nameId] ? (
                                        <SolidHeartIcon className="w-8 h-8 p-1 text-primary-400 hover:text-white hover:bg-primary-400 hover:rounded-full" />
                                    ) : (
                                        <HeartIcon className="w-8 h-8 p-1 text-gray-500 hover:text-white hover:bg-primary-400 hover:rounded-full" />
                                    )}
                                </button>
                            </Tooltip>

                            <Tooltip title="Share">
                                <ShareButton community={community} />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            {/* This is needed for the google place library */}
            <div id={"id-community-" + community.nameId} className="hidden w-0 h-0">
                {isLoaded ? (
                    <GoogleMapComponent onLoad={onLoad} onUnmount={onUnmount} />
                ) : null}
            </div>

            <CommunityTypesDialog
                open={openHelp}
                onClose={(e) => {
                    if (e) {
                        e.stopPropagation();
                    }
                    setOpenHelp(false);
                }}
            />

            {/*<Dialog
                open={openHelp}
                onClose={() => setOpenHelp(false)}
                className="fixed z-40 top-32 left-32 right-32 overflow-hidden"
            >
                <div className="flex items-center justify-center">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />

                    <div className="z-50 px-16 py-8 bg-white w-full border rounded-xl">
                        <h3 className="text-xl font-black">Community Types</h3>
                        <div className="mt-4 space-y-2">
                            {getCommunityTypesHelp(community.facilityType).map((c) => (
                                <div key={c.name} className="text-left">
                                    <span className="font-black">{c.name}</span>
                                    <span className="pl-2">{c.help}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                            </Dialog>*/}
        </LinkOrDiv>
    );
};

const LinkOrDiv = ({ community, enableLink, children }) => {
    const router = useRouter();

    const handleClick = () => {
        router.push("/community/" + community.nameId);
    };

    if (enableLink) {
        return (
            <button className="block relative w-full" onClick={handleClick}>
                {children}
            </button>
        );

        /*return (
            <Link href={"/community/" + community.nameId}>
                <a className="block relative cursor-pointer">{children}</a>
            </Link>
        );*/
    } else {
        return <div className="relative cursor-pointer">{children}</div>;
    }
};

export default CommunityCard;
